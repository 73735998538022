import { useContext } from 'react';

import { FooterContext } from '../../../../Footer.context';
import { FooterLicenseStyled } from './FooterLicense.styled';
import { FooterLicenseProps } from './FooterLicense.types';

const FooterLicense: React.FC<FooterLicenseProps> = ({ children }) => {
  const { testId } = useContext(FooterContext);

  if (!children) return null;

  return <FooterLicenseStyled data-testid={`${testId}License`}>{children}</FooterLicenseStyled>;
};

FooterLicense.displayName = 'footerLicense';

export default FooterLicense;
