import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { TFuncKey } from 'i18next';
import { useRouter } from 'next/router';
import { Footer, LocalizationContext } from 'clikalia-web-components';
import { useZones } from 'clikalia-hooks';
import { GeneralContext } from '../../../../contexts/GeneralContext';
import {
  convertStrapiURLWithLocale,
  handleAnchorEvents,
} from 'cross-config/utils/handleAnchorEvents';

import { FooterWrapper } from './HomeFooter.styled';

const HomeFooter = () => {
  const {
    siteConfig: {
      email,
      footer: { sections },
      socialMedia,
      zones,
      countries,
    },
  } = useContext(GeneralContext);
  const { t } = useTranslation('common');
  const { locale, defaultLocale } = useRouter();
  const { location, countryCode } = useContext(LocalizationContext);
  const zone = useZones({ zones, location, countryCode });
  return (
    <FooterWrapper>
      <Footer>
        <Footer.Contact logoHref={process.env.NEXT_PUBLIC_HOME_URL}>
          <Footer.Contact.Abstract>{t('footer.abstract')}</Footer.Contact.Abstract>
          {countryCode === 'PT' && (
            <Footer.Contact.License>{t('footer.license')}</Footer.Contact.License>
          )}
          <Footer.Contact.Phone phone={zone?.phone} workingHours={t('footer.workingHours')} />
          <Footer.Contact.Address address={zone?.address} url={zone.url} />
          <Footer.Contact.Email email={email} />
        </Footer.Contact>

        <Footer.Social title={t('footer.followUs')}>
          {socialMedia.map(({ name, url }) => (
            <Footer.Social.Link
              key={name}
              name={name as keyof typeof Footer.Social.Link.IconNames}
              url={url}
            />
          ))}
        </Footer.Social>

        <Footer.Sections onClick={handleAnchorEvents}>
          {sections.map(({ name: sectionName, sections: sectionContent }, index) => (
            <Footer.Sections.Section
              testId={`footerSection-${index}-`}
              key={`footer.sectionName.${sectionName}`}
              title={t(`footer.sectionName.${sectionName}` as TFuncKey) as string}
              content={
                sectionContent?.map(({ name, url }) => ({
                  text: t(`footer.section.${sectionName}.${name}` as TFuncKey) as string,
                  url: convertStrapiURLWithLocale({ url, locale, defaultLocale }),
                })) || []
              }
            />
          ))}
        </Footer.Sections>

        <Footer.Regions>
          {countries?.map(({ name, url }, key) => (
            <Footer.Regions.Region
              testId={`footerRegion-${key}`}
              key={`footer-regions-region-${key}`}
              name={name}
              label={t(`footer.webname.${name}` as TFuncKey) as string}
              url={url}
            />
          ))}
        </Footer.Regions>

        <Footer.Bottom>
          <Footer.Bottom.Copyright>{t('footer.copyright')}</Footer.Bottom.Copyright>
        </Footer.Bottom>
      </Footer>
    </FooterWrapper>
  );
};

export default HomeFooter;
